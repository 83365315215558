import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private renderer: Renderer2,
    private el: ElementRef) {

  }

  detectPhoneAndAct() {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      this.renderer.addClass(this.el.nativeElement, 'ios');
    }
  }
}
